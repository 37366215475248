import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["form", "results", "routeResults", "placeResults", "collectionResults", "minLengthDisplay", "maxLengthDisplay", "minLengthValue", "maxLengthValue", "map", "mapContainer", "filters"]
	
	connect() {
		this.timer = null;
		this.armed = false
	}
	
	arm(event) {
		this.armed = true
	}
	
	dearm(event) {
		this.armed = false
	}
	
	toggleMap(event) {
		this.mapContainerTarget.style.display = event.target.checked ? "block" : "none"
	}
	
	toggleFilters(event) {
		this.filtersTarget.style.display = event.target.checked ? "block" : "none"
	}
	
	startTimer(event) {
		clearTimeout(this.timer)
		
		this.timer = setTimeout(() => {
			this.submit(this.formTarget)
		}, 1000)
	}
	
	mapMoved(event) {
		this.formTarget.querySelector("#search_bounds").value = event.detail.bounds.toBBoxString()
		this.formTarget.querySelector("#search_zoom").value = event.detail.zoom
		
		if (this.armed) {
			this.startTimer(event)
		}
	}
	
	sort(event) {
		let sorting = event.target.value.split("-")
		let sort_key = sorting[0]
		let sort_direction = sorting[1]
		
		let container = document.getElementById(event.target.dataset.list)
		let list = [...container.children]
		
		list
			.sort((a, b) => {
				let value_a = null
				let value_b = null
				
				switch(sort_key) {
					case "title":
						value_a = a.dataset.title
						value_b = b.dataset.title
						break;
					case "length":
						value_a = parseFloat(a.dataset.length)
						value_b = parseFloat(b.dataset.length)
						break;
					case "routetype":
						value_a = a.dataset.routetype
						value_b = b.dataset.routetype
						break;
					case "placetype":
						value_a = a.dataset.placetype
						value_b = b.dataset.placetype
						break;
					case "updated_at":
						value_a = parseInt(a.dataset.updated_at)
						value_b = parseInt(b.dataset.updated_at)
						break;
					default:
						value_a = a.dataset.title
						value_b = b.dataset.title
						break;
				}
				
				let result = 1
				
				switch(sort_direction) {
					case "asc":
						result = value_a > value_b ? 1 : -1
						break;
					case "desc":
						result = value_a < value_b ? 1 : -1
						break;
					default:
						result = value_a > value_b ? 1 : -1
						break;
				}
				
				return result
			})
			.forEach(node => container.appendChild(node))
	}
	
	updateMinLengthValueDisplay(event) {
		let min_value = parseInt(event.target.value)
		let max_value = parseInt(this.maxLengthValueTarget.value)
		
		this.minLengthDisplayTarget.textContent = Math.round(min_value / 1000).toString() + " km"
		
		if (min_value > max_value) {
			this.maxLengthValueTarget.value = min_value + 10000
			this.maxLengthDisplayTarget.textContent = Math.round(this.maxLengthValueTarget.value / 1000).toString() + " km"
		}
	}
	
	updateMaxLengthValueDisplay(event) {
		let min_value = parseInt(this.minLengthValueTarget.value)
		let max_value = parseInt(event.target.value)
		
		this.maxLengthDisplayTarget.textContent = Math.round(max_value / 1000).toString() + " km"
		
		if (max_value < min_value) {
			this.minLengthValueTarget.value = max_value - 10000
			this.minLengthDisplayTarget.textContent = Math.round(this.minLengthValueTarget.value / 1000).toString() + " km"
		}
	}
	
	submit(form) {
		document.getElementById("spinner").style.display = "block"
		this.resultsTarget.style.display = "none"
		this.mapTarget.dataset.geojson = ""
		Rails.fire(form, "submit")
	}
}
